import React, { useEffect } from "react";
import "../../styles/effects/_dynamic-title.scss";

export default function DynamicTitle() {
  useEffect(() => {
    const target = document.getElementById("text-target");
    let array = [
      "Conception \u00a0 de \u00a0 site \u00a0 web",
      "Référencement \u00a0 naturel",
      "Maintenance \u00a0 technique",
      "Suivi \u00a0 de \u00a0 position \u00a0 SEO",
      "Optimisation \u00a0 technique",
      "Optimisation \u00a0 des \u00a0 mots-clés",
    ];

    let wordIndex = 0;
    let letterIndex = 0;

    const createLetter = () => {
      const letter = document.createElement("span");
      target.appendChild(letter);

      letter.textContent = array[wordIndex][letterIndex];

      setTimeout(() => {
        letter.remove();
      }, 3000);
    };
    const loop = () => {
      setTimeout(() => {
        if (wordIndex >= array.length) {
          wordIndex = 0;
          letterIndex = 0;
          loop();
        } else if (letterIndex < array[wordIndex].length) {
          createLetter();
          letterIndex++;
          loop();
        } else {
          wordIndex++;
          letterIndex = 0;
          setTimeout(loop, 3000);
        }
      }, 75);
    };
    loop();
  }, []);

  return (
    <div className="service mt-5 mb-5">
      <span id="text-target">&nbsp;</span>
    </div>
  );
}
