import React from "react";
import { NavLink } from "react-router-dom";

export default function ButtonCestParIci(props) {
  return (
    <NavLink to={props.lienCestParIci}>
      <div className="submit-btn text-center mt-1">C'est par ici...</div>
    </NavLink>
  );
}
