import React from "react";
import { NavLink } from "react-router-dom";
import Navigation from "./Navigation";

export default function Header() {
  return (
    <header className="row align-items-center shadow-header">
      <div className="col ps-xl-4 masthead-logo">
        <NavLink to="/" alt="logo FR Design" aria-label="Page home">
          <div className="custom-logo-link2" id="logofr"></div>
        </NavLink>
      </div>
      <div id="service-header" className="col text-center">
        Des solutions <span>sur-mesure</span> pour vos projets web
      </div>
      <div className="col text-center masthead-menu">
        <Navigation />
      </div>
    </header>
  );
}
