import React from "react";
import { NavLink } from "react-router-dom";

export default function ButtonLiens(props) {
  return (
    <NavLink
      to={props.buttonLink}
      target={props.buttonTarget}
      className={props.buttonClass}
    >
      <div className="submit-btn text-center">{props.buttonText}</div>
    </NavLink>
  );
}
