import React from "react";
import Coordonnees from "../footer/Coordonnees";
import Naviguation from "../footer/Naviguation";
import QuestionDevis from "../footer/QuestionDevis";
import SiteInfos from "../footer/SiteInfos";

export default function Footer() {
  return (
    <footer className="position-relative">
      <QuestionDevis />
      <Naviguation />
      <Coordonnees />
      <SiteInfos />
    </footer>
  );
}
