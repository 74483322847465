import React from "react";
import ButtonDarkLight from "../effects/ButtonDarkLight";
import MenuPresentation from "../menu/MenuPresentation";
import MenuWeb from "../menu/MenuWeb";
import MenuRef from "../menu/MenuRef";
import MenuSocial from "../menu/MenuSocial";
import MenuOffres from "../menu/MenuOffres";
import ButtonCloseCanvas from "../divers/ButtonCloseCanvas";
import ButtonLiens from "../divers/ButtonLiens";

export default function Navigation() {
  return (
    <nav className="navbar navbar-light">
      <ButtonLiens
        buttonLink="https://cal.com/frantz-fr-webdesign/premier-contact"
        buttonText="Réservez votre RDV"
        buttonTarget="_blank"
        buttonClass={"d-none d-xl-flex"}
      />

      <div className="container-fluid offcanvas-header justify-content-center justify-content-md-end">
        <ButtonDarkLight display={"d-none d-lg-block"} />
        <button
          //   ref="navbarToggler"
          className="navbar-toggler"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasNavbar"
          aria-controls="offcanvasNavbar"
          aria-label="Menu"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className="offcanvas offcanvas-end"
          tabIndex="-1"
          id="offcanvasNavbar"
          aria-labelledby="offcanvasNavbarLabel"
        >
          <div className="offcanvas-header justify-content-center justify-content-md-end">
            <ButtonDarkLight display={"d-lg-none"} />
            <button
              type="button"
              className="navbar-toggler"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            >
              <span className="btn-close"></span>
            </button>
          </div>
          <div className="offcanvas-body d-flex justify-content-center">
            <ul className="navbar-nav align-middle">
              <li className="nav-item text-center">
                <ButtonCloseCanvas text={"Home"} href={"/"} />
              </li>
              <li className="nav-item dropdown text-center">
                <MenuPresentation />
              </li>
              <li className="nav-item dropdown text-center">
                <MenuOffres />
              </li>
              <li className="nav-item dropdown text-center">
                <MenuWeb />
              </li>
              <li className="nav-item dropdown text-center">
                <MenuRef />
              </li>
              <li className="nav-item dropdown text-center">
                <ButtonCloseCanvas
                  text={"Mes projets"}
                  href={"/projets-de-site-web-professionnel"}
                />
              </li>

              <li className="nav-item dropdown text-center"></li>
              <li>
                <hr className="dropdown-divider" />
              </li>
              <li className="nav-item dropdown text-center">
                <ButtonCloseCanvas
                  text={"FAQ - Foire aux questions"}
                  href={"/faq"}
                  target={"_blank"}
                />
              </li>
              <li className="nav-item dropdown text-center">
                <ButtonCloseCanvas
                  text={'Les chroniques du web "Le blog"'}
                  href={"/blog"}
                  target={"_blank"}
                />
              </li>
              <li className="nav-item dropdown text-center">
                <ButtonCloseCanvas
                  text={"Boîte à ressources"}
                  href={"/ressources-pour-site-web"}
                />
              </li>
              <li>
                <hr className="dropdown-divider" />
              </li>
              <li>
                <em>
                  <ButtonCloseCanvas
                    text={"Contactez-moi !"}
                    href={"/contactez-moi"}
                    target={"_blank"}
                  />
                </em>
              </li>
              {/* <li className="nav-item dropdown text-center">
                <ButtonCloseCanvas
                  text={"Un devis sur-mesure ?"}
                  href={"/demandez-votre-devis"}
                  target={"_blank"}
                />
              </li> */}
            </ul>
          </div>
          <div className="offcanvas-footer">
            <form className="d-flex justify-content-center">
              <MenuSocial />
            </form>
          </div>
        </div>
      </div>
    </nav>
  );
}
