import React from "react";
import { FaGlobe } from "react-icons/fa6";
import ButtonCloseCanvas from "../divers/ButtonCloseCanvas";

export default function CanvasOffre() {
  return (
    <div id="canvas-offre">
      <button
        className="btn submit-btn"
        type="button"
        data-bs-toggle="offcanvas"
        data-bs-target="#offcanvasWithBothOptions"
        aria-controls="offcanvasWithBothOptions"
        aria-label="offre-service"
      >
        <p className="fs-4">Solutions</p>
        <FaGlobe />
      </button>

      <div
        className="offcanvas offcanvas-start"
        data-bs-scroll="true"
        tabIndex="-1"
        id="offcanvasWithBothOptions"
        aria-labelledby="offcanvasWithBothOptionsLabel"
      >
        <div className="offcanvas-header position-absolute">
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body d-flex flex-wrap align-content-center justify-content-center">
          <div>
            <div className="title-canvas mb-4">Services & Solutions</div>
            <div className="text-center">
              <ButtonCloseCanvas
                text={"Conception de site web"}
                href={"/offres/services-de-conception-de-site-web"}
                class={"dropdown-item fst-italic"}
              />
              <hr className="dropdown-divider w-25" />
              <ButtonCloseCanvas
                text={"Référencement & Local"}
                href={"/offres/services-de-referencement-de-site-web"}
                class={"dropdown-item fst-italic"}
              />
              <hr className="dropdown-divider w-25" />
              <ButtonCloseCanvas
                text={"Maintenance web & Suivi SEO"}
                href={"/offres/services-de-maintenance-de-site-web"}
                class={"dropdown-item fst-italic"}
              />
              <hr className="dropdown-divider w-25" />
              <ButtonCloseCanvas
                text={"Assistance de site web"}
                href={"/offres/assistance-web-personnalise"}
                class={"dropdown-item fst-italic"}
              />
              <hr className="dropdown-divider w-25" />
              <ButtonCloseCanvas
                text={"Tunnel de vente & Landing Page"}
                href={"/offres/services-de-tunnel-de-vente-et-landing-page"}
                class={"dropdown-item fst-italic"}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
