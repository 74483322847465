export const SocialData = [
  {
    id: 1,
    classe_a: "navbar-brand linkedin",
    link_social:
      "https://www.linkedin.com/company/frwebdesign-by-frantz-rouze/about/",
    alt: "link_social linkedin",
  },
  {
    id: 2,
    classe_a: "navbar-brand facebook",
    link_social: "https://www.facebook.com/frwebdesign.by.frantz.rouze",
    alt: "link_social Facebook",
  },
  {
    id: 3,
    classe_a: "navbar-brand instagram",
    link_social: "https://www.instagram.com/frantz_fr_webdesign/",
    alt: "link_social Instagram",
  },
  {
    id: 4,
    classe_a: "navbar-brand pinterest",
    link_social: "https://www.pinterest.fr/Frantz_FRDesign",
    alt: "link_social Pinterest",
  },
  {
    id: 5,
    classe_a: "navbar-brand youtube",
    link_social: "https://www.youtube.com/@frwebdesign59/",
    alt: "link_social Youtube",
  },
];
