import ReactGA from "react-ga";

export const initGA = () => {
  ReactGA.initialize("G-5PCBCNB5XY"); // Remplacez par votre ID de suivi
};

export const logPageView = () => {
  ReactGA.set({ page: window.location.pathname });
  ReactGA.pageview(window.location.pathname);
};

export const logEvent = (category = "", action = "", label = "") => {
  if (category && action) {
    ReactGA.event({ category, action, label });
  }
};
