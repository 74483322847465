import React from "react";
import ScrollToTop from "../components/effects/ScrollToTop";
import SectionHeader from "../components/sections/Header";
import SectionsFooter from "../components/sections/Footer";
import CanvasOffre from "../components/effects/CanvasOffre";
import CanvasContact from "../components/effects/CanvasContact";

export default function BaseLayout({ children }) {
  return (
    <>
      <ScrollToTop />
      <CanvasOffre />
      <CanvasContact />
      <SectionHeader />
      <main>
        <div id="image-fond"></div>
        <div className="container-fluid position-relative">{children}</div>
      </main>
      <SectionsFooter />
    </>
  );
}
