import React from "react";
import CountUp from "react-countup";
import { useInView } from "react-intersection-observer";

export default function CountdownFRWD() {
  const { ref, inView } = useInView({
    /* Optional options */
    triggerOnce: true, // L'image sera chargée seulement une fois
    threshold: 1, // L'image commence à charger quand elle est 100% visible
  });

  return (
    <div className=" d-flex flex-column flex-lg-row row-cols-lg-3">
      <div>
        <div className="cle" ref={ref}>
          {inView && <CountUp start={0} end={2020} separator="" />}
        </div>
        <div className="text text-uppercase">Création de FR Webdesign</div>
      </div>
      <div>
        <div className="cle" ref={ref}>
          {inView && <CountUp start={0} end={99} />}%
        </div>
        <div className="text text-uppercase">de client satisfait</div>
      </div>
      <div>
        <div className="cle" ref={ref}>
          {inView && <CountUp start={0} end={50} />}+
        </div>
        <div className="text text-uppercase">compétences informatiques</div>
      </div>
    </div>
  );
}
