import React, { useState } from "react";
import { SocialData } from "../../data/SocialData";

export default function SocialLogos({ SocialNumber }) {
  const [currentSocialLogos] = useState(SocialData[SocialNumber]);
  return (
    <li className="nav-item">
      <a
        target="_blank"
        rel="noreferrer noopener"
        href={currentSocialLogos.link_social}
        alt={currentSocialLogos.alt}
        aria-label={currentSocialLogos.alt}
      >
        <div className={currentSocialLogos.classe_a}></div>
      </a>
    </li>
  );
}
