import React from "react";
import ButtonCestParIci from "../divers/ButtonCestParIci";

export default function QuestionDevis() {
  return (
    <div className="border-top pb-5" id="question-devis">
      <div className="mt-5">
        <div className="row justify-content-center reveal">
          <div className="col-10 col-lg-4  text-center">
            <div className="titre_footer text_blue reveal-1">
              Une question ?
            </div>
            <p className="reveal-2">
              Un renseignement sur mes services. <br />
              Je vous répondrais au plus vite (24h max).
            </p>
            <div className="row justify-content-center">
              <div className="col-xxl-3 col-xl-4 col-lg-5 col-sm-3 col-6 reveal-3">
                <ButtonCestParIci lienCestParIci={"/contactez-moi"} />
              </div>
            </div>
          </div>
          <div className="col-10 col-lg-4 mt-5 mt-lg-0 text-center">
            <div className="titre_footer text_blue reveal-1">FAQ</div>
            <p className="reveal-2">
              Quelques réponses <br />à vos principales questions.
            </p>
            <div className="row justify-content-center">
              <div className="col-xxl-3 col-xl-4 col-lg-5 col-sm-3 col-6 reveal-3">
                <ButtonCestParIci lienCestParIci={"/faq"} />
              </div>
            </div>
          </div>
          {/* <div className="col-10 col-lg-4 mt-5 mt-lg-0 text-center">
            <div className="titre_footer text_blue reveal-1">
              Un devis sur-mesure ?
            </div>
            <p className="reveal-2">
              Besoin d'un devis pour un projet. <br />
              J'y répondrais dans les plus bref délais.
            </p>
            <div className="row justify-content-center">
              <div className="col-xxl-3 col-xl-4 col-lg-5 col-sm-3 col-6 reveal-3">
                <ButtonCestParIci lienCestParIci={"/demandez-votre-devis"} />
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}
