import React from "react";
import SocialLogos from "../divers/SocialLogos";

export default function MenuSocial() {
  return (
    <nav className="navbar navbar-expand navbar-light">
      <div className="container-fluid">
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav">
            <SocialLogos SocialNumber={0} />
            <SocialLogos SocialNumber={1} />
            <SocialLogos SocialNumber={2} />
            <SocialLogos SocialNumber={3} />
            <SocialLogos SocialNumber={4} />
          </ul>
        </div>
      </div>
    </nav>
  );
}
