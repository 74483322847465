import { useEffect, useState } from "react";
// import DarkMode from "../../assets/icons/lune-black.svg";
// import LightMode from "../../assets/icons/soleil-white.svg";

export default function ButtonDarkLight(props) {
  const [theme, setTheme] = useState("light");

  console.log(theme);
  const onSelectMode = (theme) => {
    setTheme(theme);
    if (theme === "dark") {
      document.body.setAttribute("data-theme", "dark");
    } else {
      document.body.removeAttribute("data-theme", "light");
    }
  };

  useEffect(() => {
    // Add listener to update styles
    window
      .matchMedia("(prefers-color-scheme: dark)")
      .addEventListener("change", (e) =>
        onSelectMode(e.matches ? "dark" : "light")
      );

    // Setup dark/light mode for the first time
    onSelectMode(
      window.matchMedia("(prefers-color-scheme: dark)").matches
        ? "dark"
        : "light"
    );

    // Remove listener
    return () => {
      window
        .matchMedia("(prefers-color-scheme: dark)")
        .removeEventListener("change", () => {});
    };
  }, []);

  return (
    <div className={props.display}>
      {/* <div className="navbar navbar-light ms-4 ms-md-0 me-md-3 dark-light-mode">
        <div className="container-fluid">
          <div className="switch-label">
            <button onClick={() => onSelectMode("switch-toggle")}>
              <img
                className="button_dark"
                alt="Icone d'une lune pour afficher le site sur un fond sombre"
                src={DarkMode}
              />
            </button>
            <p>&nbsp;</p>
            <button
              onClick={() =>
                onSelectMode("switch-toggle switch-toggle-checked")
              }
            >
              <img
                className="button_light"
                alt="Icone d'un soleil pour afficher le site sur un fond clair"
                src={LightMode}
              />
            </button>
            <div className={theme}></div>
          </div>
        </div>
      </div> */}
    </div>
  );
}
