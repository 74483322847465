import React from "react";
import ButtonLiens from "../../components/divers/ButtonLiens";
import { Col, Row } from "react-bootstrap";
import DynamicTitle from "../../components/effects/DynamicTitle";

const Intro = () => {
  return (
    <Row className="intro position-relative" id="header_bg_home">
      <Col className="d-flex flex-column align-items-center justify-content-center">
        <h1>
          <div className="question">
            Un site web professionnel sur-mesure . . .
          </div>
          <div className="reponse fst-italic">
            . . . la clé de votre visibilité en ligne !
          </div>
        </h1>
        <DynamicTitle />
        <div className="slogan">
          Vous avez une vision, je vous aide à la concrétiser !
        </div>
        <ButtonLiens
          buttonLink="https://zcal.co/fr-design/premier-contact"
          buttonText="Réservez votre appel"
          buttonTarget="_blank"
          buttonClass="mt-5"
        />
      </Col>
    </Row>
  );
};

export default Intro;
