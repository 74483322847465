import React from "react";
import ButtonCloseCanvas from "../divers/ButtonCloseCanvas";
import { NavLink } from "react-router-dom";

export default function MenuRef() {
  return (
    <div className="flex-column d-flex">
      <NavLink
        className="nav-link dropdown-toggle"
        href="#"
        id="navbarDropdownRef"
        role="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        Côté Référencement
      </NavLink>
      <ul className="dropdown-menu" aria-labelledby="navbarDropdownRef">
        <li className="text-center">
          <ButtonCloseCanvas
            text={'Référencement "SEO & SEA"'}
            href={"/referencement-pour-site-web-professionnel"}
            class={"dropdown-item fw-bold"}
          />
        </li>
        <li>
          <hr className="dropdown-divider" />
        </li>
        <li className="text-center">
          <ButtonCloseCanvas
            text={"Référencement Naturel | SEO"}
            href={
              "/referencement-pour-site-web-professionnel/referencement-naturel-seo"
            }
            class={"dropdown-item fst-italic"}
          />
        </li>
        <li className="text-center">
          <ButtonCloseCanvas
            text={"Référencement Local"}
            href={
              "/referencement-pour-site-web-professionnel/referencement-local"
            }
            class={"dropdown-item fst-italic"}
          />
        </li>
        <li className="text-center">
          <ButtonCloseCanvas
            text={"Campagne publicitaire | SEA"}
            href={
              "/referencement-pour-site-web-professionnel/campagne-publicitaire-sea"
            }
            class={"dropdown-item fst-italic"}
          />
        </li>
        <li className="text-center">
          <ButtonCloseCanvas
            text={"Landing Page - Tunnel de Vente"}
            href={
              "/referencement-pour-site-web-professionnel/tunnel-de-vente-et-landing-page"
            }
            class={"dropdown-item fst-italic"}
          />
        </li>
      </ul>
    </div>
  );
}
