import React from "react";
import CountdownFRWD from "../../components/effects/CountdownFRWD";

export default function ChiffresCle() {
  return (
    <section id="home_compteurs">
      <div className="row w-100 expertise_fond">
        <div id="compteur" className="container">
          <h2 className="text-center titre-compteur">
            <mark>FR Webdesign</mark> <br /> en quelques chiffres :
          </h2>

          <div id="chiffres" className="text-center">
            <CountdownFRWD />
          </div>

          <p className="text-center texte_expertises">
            <mark>FR Webdesign</mark>, c'est un savoir-faire en création de site
            professionnel qui respecte les règles du web, tout en s'adaptant à
            votre projet en ligne <br />
            <br />
            {/* <mark>
              <em>Et surtout un accompagnement sur-mesure !</em>
            </mark> */}
          </p>
          <div id="expertises" className="text-center">
            Et + de 20 ans d'expertise à votre service !
          </div>
        </div>
      </div>
    </section>
  );
}
