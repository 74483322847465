import React from "react";
import { NavLink } from "react-router-dom";
import MenuSocial from "../menu/MenuSocial";

export default function Coordonnees() {
  return (
    <div className="border-top pb-5" id="coordonnees-footer">
      <div className="container mt-5">
        <div className="row align-items-center reveal">
          <div className="col-lg col-12 text-center reveal-1">
            <div className="titre_footer text_blue">Contactez-moi . . .</div>
            <div className="sous-titre_footer mb-0 coordonnees-footer-text">
              <a className="coordonnees-footer-text" href="tel:+0682030266">
                06 82 03 02 66
              </a>{" "}
              &nbsp;|&nbsp;{" "}
              <a
                className="coordonnees-footer-text"
                href="mailto:contact@frwebdesign.net"
              >
                contact@frwebdesign.net
              </a>
            </div>
          </div>
          <div className="col-lg col-12 text-center mt-5 mt-lg-0 reveal-2">
            <NavLink to="/" alt="logo FR Design" aria-label="Page home">
              <div id="logofr" className="custom-logo-link logo-footer"></div>
            </NavLink>
          </div>
          <div className="col-lg col-12 text-center mt-lg-0 mt-5 reveal-3">
            <div className="titre_footer text_blue">Suivez-moi . . .</div>
            <div className="d-flex justify-content-center">
              <MenuSocial />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
