import React from "react";
import { NavLink } from "react-router-dom";

export default function Naviguation() {
  return (
    <div className="border-top">
      <div className="mt-5 mb-5">
        <div className="row">
          <div className="col-xl col-lg-4 col-12 text-center">
            <div className="text_blue titre_nav_footer">
              <strong>Présentation</strong>
            </div>
            <ol className="ps-0">
              <li className="navbar-nav">
                <NavLink
                  className="text-center text_nav_footer"
                  aria-current="page"
                  to="/presentation/studio-web"
                >
                  <i>L'agence web & SEO</i>
                </NavLink>
              </li>
              <li className="navbar-nav">
                <NavLink
                  className="text-center text_nav_footer my-lg-1"
                  aria-current="page"
                  to="/presentation/webdesigner-et-developpeur"
                >
                  <i>Webmaster & Développeur</i>
                </NavLink>
              </li>
              <li className="navbar-nav">
                <NavLink
                  className="text-center text_nav_footer"
                  aria-current="page"
                  to="/presentation/mission"
                >
                  <i>La mission</i>
                </NavLink>
              </li>
            </ol>
          </div>
          <div className="col-xl col-lg-4 col-12 text-center mt-5 mt-lg-0">
            <div className="text_blue titre_nav_footer">
              <strong>Services & Solutions</strong>
            </div>
            <ol className="ps-0">
              <li className="navbar-nav">
                <NavLink
                  className="text-center text_nav_footer"
                  aria-current="page"
                  to="/offres/services-de-conception-de-site-web"
                >
                  <i>Conception de site web</i>
                </NavLink>
              </li>
              <li className="navbar-nav">
                <NavLink
                  className="text-center text_nav_footer my-lg-1"
                  aria-current="page"
                  to="/offres/services-de-referencement-de-site-web"
                >
                  <i>Référencement "SEO & Local"</i>
                </NavLink>
              </li>
              <li className="navbar-nav">
                <NavLink
                  className="text-center text_nav_footer mb-lg-1"
                  aria-current="page"
                  to="/offres/services-de-maintenance-de-site-web"
                >
                  <i>Maintenance web & Suivi SEO</i>
                </NavLink>
              </li>
              <li className="navbar-nav">
                <NavLink
                  className="text-center text_nav_footer"
                  aria-current="page"
                  to="/offres/assistance-web-personnalise"
                >
                  <i>Assistance de site web</i>
                </NavLink>
              </li>
            </ol>
          </div>
          <div className="col-xl col-lg-4 col-12 text-center mt-lg-0 mt-sm-5 mt-5">
            <div className="text_blue titre_nav_footer">
              <strong>Projets - Portfolio</strong>
            </div>
            <ol className="ps-0">
              <li className="navbar-nav">
                <NavLink
                  className="text-center text_nav_footer"
                  aria-current="page"
                  to="/projets-de-site-web-professionnel"
                >
                  <i>Projets</i>
                </NavLink>
              </li>
            </ol>
          </div>
          <div className="col-xl col-lg-6 col-12 text-center mt-xl-0 mt-sm-5 mt-5">
            <div className="text_blue titre_nav_footer">
              <strong>Ressources</strong>
            </div>
            <ol className="ps-0">
              <li className="navbar-nav">
                <NavLink
                  className="text-center text_nav_footer"
                  aria-current="page"
                  target="_blank"
                  to="/faq"
                >
                  <i>FAQ - Foire aux questions</i>
                </NavLink>
              </li>
              <li className="navbar-nav px-5 margin_filet">
                <hr className="dropdown-divider" />
              </li>
              <li className="navbar-nav">
                <NavLink
                  className="text-center text_nav_footer"
                  aria-current="page"
                  target="_blank"
                  to="/ressources-pour-site-web"
                >
                  <i>Boîte à ressources</i>
                </NavLink>
              </li>
            </ol>
          </div>
          <div className="col-xl col-lg-6 col-12 text-center mt-xl-0 mt-sm-5 mt-5">
            <div className="text_blue titre_nav_footer">
              <strong>Contact</strong>
            </div>
            <ol className="ps-0">
              <li className="navbar-nav">
                <NavLink
                  className="text-center text_nav_footer"
                  aria-current="page"
                  target="_blank"
                  to="/contactez-moi"
                >
                  <i>Une question ? Un renseignement ?</i>
                </NavLink>
              </li>
              {/* <li className="navbar-nav px-5 margin_filet">
                <hr className="dropdown-divider" />
              </li>
              <li className="navbar-nav">
                <NavLink
                  className="text-center text_nav_footer"
                  aria-current="page"
                  target="_blank"
                  to="/demandez-votre-devis"
                >
                  <i>Un devis sur-mesure ?</i>
                </NavLink>
              </li> */}
            </ol>
          </div>
        </div>
      </div>
    </div>
  );
}
