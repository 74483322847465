import React from "react";
import { NavLink } from "react-router-dom";
import ButtonCloseCanvas from "../divers/ButtonCloseCanvas";

export default function MenuPresentation() {
  return (
    <div className="flex-column d-flex">
      <NavLink
        className="nav-link dropdown-toggle"
        href="#"
        id="navbarDropdownPresentation"
        role="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        Présentation
      </NavLink>
      <ul
        className="dropdown-menu"
        aria-labelledby="navbarDropdownPresentation"
      >
        <li className="text-center">
          <ButtonCloseCanvas
            text={"L'agence web & SEO"}
            href={"/presentation/studio-web"}
            class={"dropdown-item fst-italic"}
          />
        </li>
        <li className="text-center">
          <ButtonCloseCanvas
            text={"Webmaster & Développeur"}
            href={"/presentation/webdesigner-et-developpeur"}
            class={"dropdown-item fst-italic"}
          />
        </li>
        <li className="text-center">
          <ButtonCloseCanvas
            text={"Ma mission"}
            href={"/presentation/mission"}
            class={"dropdown-item fst-italic"}
          />
        </li>
      </ul>
    </div>
  );
}
