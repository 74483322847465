import React from "react";
import { NavLink } from "react-router-dom";

export default function ButtonCloseCanvas(props) {
  return (
    <button
      type="button"
      className="navbar-toggler m-0"
      data-bs-dismiss="offcanvas"
      aria-label="Close"
    >
      <NavLink
        className={props.class + " nav-link"}
        aria-current="page"
        to={props.href}
        target={props.target}
      >
        {props.text}
      </NavLink>
    </button>
  );
}
